(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name referees.referee.controller:RefereeCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.public.classificacio')
    .controller('classificacioCtrl', classificacioCtrl);

  function classificacioCtrl(classificacions) {
   var vm = this;
  
   
  }
}());
